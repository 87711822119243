/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import GlobalQueryParams from "../GlobalQueryParams";
import SchemaScript from "../SchemaScript";
import LayoutSEOQuery from "../../assets/StaticQueries/LayoutSEO.query";
import ModalContainer from "../../containers/ModalContainer";
import PageScripts from "../PageScripts";
import builder from "@builder.io/react";
import { StringParam, useQueryParam } from "use-query-params";
import { externalRedirect } from "@utils/externalRedirect";
import { Cookie } from "@utils/Cookie";

builder.init(process.env.GATSBY_BUILDER_API_KEY!);

type DataProps = {
  props: any;
  element: any;
};

type ExternalDataType = {
  sourceUrl: string;
  targetUrl: string;
};

const PageElementWrapper: React.FC<DataProps> = ({ element, props }) => {
  const seo = LayoutSEOQuery();
  const [p] = useQueryParam("p", StringParam);
  const [experiment] = useQueryParam("experiment", StringParam);

  useEffect(() => {
    (async function () {
      const redirectList = await externalRedirect();

      if (redirectList && redirectList.length > 0) {
        redirectList.forEach(item => {
          const redirect = item?.data?.redirect;

          const currentUrl = redirect?.find(
            (item: ExternalDataType) =>
              item?.sourceUrl === window.location.pathname
          );

          if (currentUrl) {
            const newUrl = p
              ? `${currentUrl?.targetUrl}${p}`
              : currentUrl?.targetUrl;

            return (window.location.href = newUrl);
          }
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (experiment) {
      Cookie.setCookie("RedesignCohort", "2", 365);
      Cookie.setCookie("ExperimentExclude", "true", 365);
    }
  }, [experiment]);

  const rebrandPage =
    props?.data?.allBuilderModels?.onePageFullNav?.content?.data?.rebrandPage;

  useEffect(() => {
    rebrandPage
      ? document.body.classList.add("sp-redesign")
      : document.body.classList.remove("sp-redesign");
  }, [rebrandPage]);

  useEffect(() => {
    if (p) {
      Cookie.setCookie("PROMOCODE", p, 365);
    }
    if (
      Boolean(
        JSON.parse(localStorage.getItem("visitedPricingPage") as string)?.value
      )
    ) {
      const expireDate = JSON.parse(
        localStorage.getItem("visitedPricingPage") as string
      ).expiresOn;
      if (Date.now() > expireDate) {
        localStorage.removeItem("visitedPricingPage");
      }
    }
  }, []);

  const options = {
    schemaReplacement: {
      from: "https://spgatsby.wpengine.com",
      to: "https://www.simplepractice.com",
    },
  };

  useEffect(() => {
    window.Osano?.cm?.addEventListener("osano-cm-consent-changed", change => {
      const data = {
        do_not_share: window.Osano.cm?.optOut,
        initiator: "User",
        object: "Privacy Choices",
        action: "Updated",
        url: window.location.pathname,
        source_application: "Marketing",
      };
      if (window?.trackMixpanelEvent) {
        window.trackMixpanelEvent("Privacy Choices Updated", data);
      }
      if (window?.analytics?.track) {
        window?.analytics?.track("Privacy Choices Updated", data);
      }
    });
  }, []);

  return (
    <SEOContext.Provider value={{ global: seo, options }}>
      <ModalContainer {...props}>
        <PageScripts />
        <SchemaScript
          jsonScript={props.pageContext?.jsonLdSchema?.schemaScript}
        />
        <GlobalQueryParams />
        {element}
      </ModalContainer>
    </SEOContext.Provider>
  );
};

export default PageElementWrapper;
